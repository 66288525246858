.certificate-label {
    background-color: #061b2d; /* Set your desired background color */
    color: #fff; /* Set your desired text color */
    font-family: montserrat; /* Set your desired font family */
    font-size: 40px; /* Set your desired font size */
    font-weight: bold; /* Set your desired font weight */
    padding: 30px 20px; /* Set your desired padding */
    text-align: center; /* Set the text alignment to center */
}

