.pdf-viewer {
    font-family: montserrat;
    box-shadow: black;
    width: 100%;
    max-width: 1523px;
    @media screen and (max-width: 768px) { /* Adjust breakpoint as needed */
        flex-direction: column;
        justify-content: space-between;
        gap: 20px; /* Adjust gap for smaller screens */
      }
  }
  .react-pdf__Page{
    box-shadow: 0 4px 8px 0;
    width: 100%;
    height: auto;
  }
  .loading {
    font-size: 16px;
    color: #000;
    margin-top: 20px;
  }
  
  canvas {
    max-width: 100%;
    height: auto !important;
  }
  .react-pdf__Page__annotations.annotationLayer {
    display: none;
}
.react-pdf__Page__textContent.textLayer {
    display: none;
}

.download_button {
    display: contents;
}

.right_handle_bar{
    flex: 0 0 550px;
    padding: 10px;
    padding-right: 2%;
    display: flex;
    flex-direction: column;
    gap: 105px; /* Adjust the gap as needed */
    justify-content: space-between;

    @media screen and (max-width: 1400px) { /* Adjust breakpoint as needed */
      flex-direction: column;
      justify-content: space-between;
      gap: 0px; /* Adjust gap for smaller screens */
    }
    /* Responsive adjustments */
    @media screen and (max-width: 768px) { /* Adjust breakpoint as needed */
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 450px;
      gap: 20px; /* Adjust gap for smaller screens */
    }
    
}

.social_button_list{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
}
.social_button_list li{
    width: 30px;
    height: 30px;
    margin: 0 0 0 20px !important;
    
}

.verification-title{
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
    text-align: center
}

.course_issue_date{
    display: flex;
    justify-content: space-between;
}

.Certificate_information {
  background: #f4f6fc;
  padding: 12px;
}
.react-pdf__Document {
  padding: 10px;
}

.Certificate_container {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1400px) { /* Adjust breakpoint as needed */
    flex-direction: row;
    justify-content: space-between;
    gap: 20px; /* Adjust gap for smaller screens */
  }
  @media screen and (max-width: 1050px) { /* Adjust breakpoint as needed */
    flex-direction: column;
    justify-content: space-between;
    gap: 20px; /* Adjust gap for smaller screens */
  }
 
}

.pdf_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.course_information {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 40px;
}