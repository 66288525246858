header {
    padding: 10px;
}

/* Navbar container  */
.navcontainer{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
    max-width: 1492px;

}
.logo{
    height: auto;
    width: 250px;
}

img{
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    object-fit: contain;
}

/* Navmenu for contac us and about us*/
.navmenu{
    width: 40%;
    display: flex;
    justify-content: flex-start;
}
.navmenu ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.navmenu li {
    
    font-family: "montserrat";
    font-size: 18px;
    font-weight: 600;
    color: #071626;
}

.navmenu li a{
    padding: 15px;
    text-decoration: none;
    color:#071626;
    fill: #000000;
    position: relative;
}

a:hover, a:focus{
    text-decoration: underline;
    color: #071626;
    
}

a::after{
    content: '';
    height: 3px;
    width: 100%;
    background: #071626;
    position: absolute;
    left: 0;
    bottom: -10px;
    opacity: 0;
    transition: all 0.4s;
    pointer-events: none;
}
a:hover::after, a:focus::after{
    opacity: 1;
}

/* login button*/
.loginbutton{
    padding: 12px 24px;
    background-color: #051A2D;
    border-style: solid;
    border-width: 1px;
    border-color: #6EC1E4;
    border-radius: 5px;
    color:white;
    font-size: 15px;
    font-family: "montserrat";
    font-weight: 600;
}

/* button:hover{
    color: #051A2D;
    background: transparent;
    border-color: #051A2D;
    transition: all 0.3s;
} */

.elementor-menu-toggle {
    display: none;
    align-items: center;
    justify-content: center;
    font-size: var(--nav-menu-icon-size, 22px);
    padding: .25em;
    cursor: pointer;
    border: 0 solid;
    border-radius: 3px;
    background-color: transparent;
    color: #33373d;
}
.elementor-nav-menu--dropdown{
   display: none;

}

.elementor-nav-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    margin: 0;
}

.elementor-nav-menu li{
    width: 100%;
}

a.elementor-item {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}
a.elementor-item:hover {
    background-color: #2263A8;
    width: 100%;
}

.elementor-nav-menu--dropdown.open {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #071626;
    border-radius: 10px;

  }



@media only screen and (max-width: 735px){
    .navmenu, .loginbutton{
        display: none;
    }
    .elementor-menu-toggle{
        display: block;
    }
    
}

.elementor-menu-toggle{
    position: relative;
    width: 50px;
    height: 50px;
}

.svg, span{
    position: absolute;
    top: 0;
    left: 0;
}

